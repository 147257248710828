.marker {
    cursor: pointer;
    height: 116px;
    width: 100%;
    position: relative;

    &.hidden {
        display: none;
    }

    &-time {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 6px;
        background: $primary-second;
        border-radius: 2px;
        color: $white;
        width: fit-content;
        margin: 0 auto;

        &.red-marker {
            background-color: $cinnabar;
        }

        &.inactive-marker {
            background-color: $dark-gray;
        }

        img {
            margin-right: 5px;
        }
    }

    &-image-container {
        position: relative;
        display: flex;
        justify-content: center;
        margin: 5px auto;
        width: 65px;

        .connection-type {
            width: 16px;
            height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: $white;
            border: 1px solid $primary-second;
            position: absolute;
            right: 7px;
            top: -2px;
            z-index: 1;

            &.inactive-marker {
                border-color: $dark-gray;

                img {
                    filter: invert(69%) sepia(5%) saturate(20%) hue-rotate(23deg) brightness(88%)
                        contrast(93%);
                }
            }

            &.red-marker {
                border-color: $cinnabar;
                img {
                    filter: invert(50%) sepia(88%) saturate(4266%) hue-rotate(334deg) brightness(96%)
                        contrast(98%);
                }
            }

            img {
                width: 12px;
                height: 12px;
            }
        }

        .m-image {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
            top: 6px;

            &.red-marker {
                filter: invert(50%) sepia(88%) saturate(4266%) hue-rotate(334deg) brightness(96%)
                    contrast(98%);
            }

            &.inactive-marker {
                filter: invert(69%) sepia(5%) saturate(20%) hue-rotate(23deg) brightness(88%) contrast(93%);
            }
        }
    }

    &-name {
        padding: 2px 6px;
        font-weight: bold;
        text-align: center;
        font-size: 10px;
        line-height: 15px;
        color: $boulder;
        background-color: $white;
        border-radius: 2px;
        box-shadow: 0px 1px 6px rgba(99, 99, 99, 0.2);
        width: 70px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.history-pin {
    height: 10px;
    width: 10px;
    background-color: $white;
    border-radius: 50%;
    border: 2px solid $primary-second;
    cursor: pointer;

    &.selected {
        transform: scale(2.7);
        position: relative;
        z-index: 999;
    }
}

.history-stop-pin {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    cursor: pointer;

    svg {
        fill: $primary-second;
    }

    &.selected {
        transform: scale(1.9);
        position: relative;
        z-index: 999;
    }
}

.user-pin {
    height: 30px;
    width: 30px;
    background-color: $primary-second;
    border-radius: 50%;
    border: 6px solid $primary-fifth;
    box-sizing: border-box;

    &.hidden {
        display: none;
    }
}

// infowindow restyle
.gm-style-iw-tc {
    display: none;
}
.gm-style-iw-t {
    bottom: 6px !important;
}
.gm-style-iw-t::after {
    display: none;
}
.gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-t::after {
    background: $primary-second !important;
}

.gm-style-iw {
    height: 22px;
    border-radius: 2px !important;
    padding: 0 !important;
    cursor: pointer !important;
    .gm-style-iw-d {
        overflow: hidden !important;
    }
    .zone-title {
        max-width: 100px;
        font-size: 12px;
        line-height: 15px;
        padding: 4px 12px;
        margin: 0;
        color: $white;
    }
    button {
        display: none !important;
    }
}

.pac-container {
    bottom: 105px;
    top: unset !important;
}