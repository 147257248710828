@import 'consts';

.btn {
    display: block;
    font-size: 18px;
    font-weight: 400;
    line-height: 40px !important;
    text-decoration: none;
    text-align: center;
    border-radius: 6px !important;
    border-width: 2px !important;
    border-style: solid !important;
    cursor: pointer;

    &.primary {
        background-color: $primary;
        color: $white;
        border-color: $primary;

        &:hover {
            background-color: $white;
            color: $primary;
            border-color: $primary;
        }
    }

    &.primary-light {
        background-color: $primary-second-light;
        color: $primary-second;
        border-color: $primary-second-light;

        &:hover {
            background-color: $white;
            color: $primary-second;
            border-color: $primary-second;
        }
    }

    &.primary-second-light {
        background-color: transparent;
        color: $primary-second;
        border-color: transparent;

        &:hover {
            background-color: $primary-second-light;
            color: $primary-second;
            border-color: $primary-second;
        }
    }

    &.primary-dark {
        background-color: $wild-sand;
        color: $dark;
        border-color: $wild-sand;

        &:hover {
            background-color: $white;
            border-color: $dark;
        }
    }

    &.basic {
        background-color: $white;
        color: $gray;
        border-color: $white;
        &:hover {
            background-color: $white;
            color: $primary;
            border-color: $primary;
        }
    }

    &.basic-no-hover {
        background-color: $white;
        color: $gray;
        border-color: $white;
        pointer-events: none;
        &:hover {
            background-color: $white;
            color: $gray;
            border-color: $white;
            cursor: not-allowed;
        }
    }

    &.danger {
        background-color: $lavender;
        color: $cinnabar;
        border-color: $lavender;
        &:hover {
            background-color: $white;
            border-color: $cinnabar;
        }

    }

    &.outline {
        background-color: transparent;
        border: 1px solid $white;
        color: $white;

        &-bordered {
            color: $mine-shaft;
            border-radius: 4px;
            border: 1px solid $gallery !important;
        }
    }

    &-dialog-close {
        padding: 0 55px;
        outline: none;
    }

    &.mat-button-disabled,
    &:disabled {
        background-color: $alto;
        color: $white !important;
        border-color: $alto;

        &:hover {
            background-color: $alto;
            color: $white;
            border-color: $alto;
            cursor: not-allowed;
        }
    }
}
