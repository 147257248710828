@import './skins/default';
@import './skins/trackimo';
@import './skins/tnt';

// default colors
body {
    --white: #ffffff;
    --hawkes-blue: #e3f2fd;
    --dark: #333333;
    --gray: #616161;
    --alto: #e0e0e0;
    --wild-sand: #f5f5f5;
    --gallery: #eeeeee;
    --fruit-salad: #4caf50;
    --manatee: #8e8e93;
    --mine-shaft: #212121;
    --boulder: #757575;
    --alabaster: #fafafa;
    --silver: #bdbdbd;
    --lavender: #ffebee;
    --cinnabar: #e53935;
    --orange: #ff9800;
    --dark-gray: #999999;
    --title-black: #4a4d4e;
    --light-orange: #fff3e0;
}

body.trackimo {
    --primary: #1976d2;
    --primary-second: #1976d2;
    --primary-second-light: #e3f2fd;
    --primary-third: #1976d2;
    --primary-fourth: #1976d2;
    --primary-fifth: #90caf9;
    --primary-opacity-10: #1976d219;
}

body.mamori {
    --primary: #1976d2;
    --primary-second: #1976d2;
    --primary-second-light: #e3f2fd;
    --primary-third: #1976d2;
    --primary-fourth: #1976d2;
    --primary-fifth: #90caf9;
    --primary-opacity-10: #1976d219;
}

body.mytracki {
    --primary: #1976d2;
    --primary-second: #1976d2;
    --primary-second-light: #e3f2fd;
    --primary-third: #1976d2;
    --primary-fourth: #1976d2;
    --primary-fifth: #90caf9;
    --primary-opacity-10: #1976d219;
}

body.tagandtrack {
    --primary: #e60000;
    --primary-second: #007c92;
    --primary-second-light: #ebf2f4;
    --primary-third: #333333;
    --primary-fourth: #1976d2;
    --primary-fifth: #95c4cd;
    --primary-opacity-10: #e6000019;
}
