$white: var(--white);
$hawkes-blue: var(--hawkes-blue);
$dark: var(--dark);
$gray: var(--gray);
$alto: var(--alto);
$wild-sand: var(--wild-sand);
$gallery: var(--gallery);
$fruit-salad: var(--fruit-salad);
$manatee: var(--manatee);
$mine-shaft: var(--mine-shaft);
$boulder: var(--boulder);
$alabaster: var(--alabaster);
$silver: var(--silver);
$lavender: var(--lavender);
$cinnabar: var(--cinnabar);
$orange: var(--orange);
$dark-gray: var(--dark-gray);
$title-black: var(--title-black);
$light-orange: var(--light-orange);
