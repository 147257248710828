@import '~@angular/material/theming';
@import 'consts';
@import 'screens';

@include mat-core();

$default-app-theme: mat-light-theme(
    (
        color: (
            primary: mat-palette($mat-blue, 700),
            accent: mat-palette($mat-pink, A200, A100, A400),
            warn: mat-palette($mat-red),
        ),
    )
);

$tnt-app-theme: mat-light-theme(
    (
        color: (
            primary: mat-palette($mat-cyan, 800),
            accent: mat-palette($mat-pink, A200, A100, A400),
            warn: mat-palette($mat-red),
        ),
    )
);

@include angular-material-theme($default-app-theme);

.tagandtrack {
    @include angular-material-theme($tnt-app-theme);
}

.mat-horizontal-stepper-header {
    pointer-events: none;
}

.form-field-custom {
    .mat-form-field-infix {
        padding: 0.5em 0 0.9em 0;
    }

    .mat-select-arrow-wrapper {
        transform: none !important;
    }

    input.mat-input-element {
        font-size: 16px;
    }

    .mat-form-field-label-wrapper {
        top: -20px;
        padding-top: 0.84375em;
        font-size: 16px;
    }
}

.mat-dialog-container {
    overflow-x: hidden !important;
}

.no-scroll {
    overflow: hidden;
}

//modal with for mobile override
@include mobile-only-md() {
    .cdk-global-overlay-wrapper {
        .cdk-overlay-pane {
            width: 95% !important;
            max-width: 95vw !important;
        }
    }
}

.menu-device {
    .mat-menu-content {
        padding-top: 14px !important;
    }
}

// override table header
.mat-header-cell {
    color: $gray;
}
