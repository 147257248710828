// /* You can add global styles to this file, and also import other style files */
@import '~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css';
@import 'buttons';
@import 'map';
@import 'screens';

html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    color: $dark;
}

svg {
    width: 100%;
    height: 100%;
}

.form-field {
    width: 100%;
    box-sizing: border-box;
}

.d-flex-center-sb {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    line-height: 24px;
    min-width: 24px;
    fill: $primary-second;

    &.no-fill {
        stroke: $primary-second;
        fill: none;
    }

    &.map-control {
        stroke: $gray;
    }
}

.scroll-viewport {
    width: 100%;
    height: 100%;
}
.cdk-virtual-scroll-content-wrapper {
    width: 100%;
}

.text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

// base sidebar layout
.section {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100%;
    overflow: hidden;

    &-content {
        flex: 1 1 auto;
        overflow-y: auto;
    }

    &-header,
    &-footer {
        flex: 0 0 auto;
    }

    .btn-container {
        padding: 15px;
        z-index: 1;
        box-shadow: 0px -4px 4px rgba(212, 212, 212, 0.3);

        .btn {
            width: 100%;
        }
    }
}

// Material override
.mat-step-header {
    &.mat-horizontal-stepper-header {
        height: 52px;
        background: $alabaster;
        border-radius: 4px;
    }
    .mat-step-icon {
        width: 32px;
        height: 32px;
    }
}
.stepper {
    .mat-stepper-horizontal-line {
        border-top-color: $gallery;
    }
}

.tooltip {
    border: none !important;
    padding: 0 !important;
    max-width: 100% !important;
    .tooltip-arrow {
        display: none;
    }
}

.mat-dialog-container {
    @include mobile-only() {
        padding: 24px 12px !important;
    }
}

.search-autocomplete {
    .mat-optgroup-label {
        padding: 0 !important;
    }
}

// Datetimepicker override
.owl-dt-popup {
    @include height-sm() {
        top: 0 !important;
        transform: unset !important;
    }
}
.owl-history {
    margin-top: 60px;
}
.owl-dt-container {
    border-radius: 4px;
    overflow: scroll;
}
.owl-dt-calendar {
    width: auto !important;
    margin: 15px;
    &.owl-dt-container-row {
        border-bottom: none;
        height: unset;
    }
    .owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-weekdays th {
        padding-bottom: 3px;
        font-size: 12px;
    }
    .owl-dt-calendar-control {
        .owl-dt-calendar-control-content {
            color: #757575;
            font-size: 14px;
            line-height: 14px;
            .owl-dt-control-content {
                font-weight: 500;

                .owl-dt-control-button-arrow {
                    margin-left: 10px;
                    margin-top: 5px;
                    transform: rotate(0deg) !important;
                    &::before {
                        content: url('^assets/images/arrow.svg');
                    }
                    svg {
                        display: none;
                    }
                }
            }
        }
    }

    .owl-dt-calendar-main {
        padding: 0;
        .owl-dt-calendar-view {
            .owl-dt-calendar-month-table {
                margin-top: 15px;
                .owl-dt-calendar-cell {
                    padding-top: 6.4% !important;
                    padding-bottom: 6.4% !important;
                }

                .owl-dt-calendar-cell-content {
                    width: 36px !important;
                    height: 32px !important;
                    font-size: 12px;
                }
            }
        }
    }

    .owl-dt-calendar-table-divider {
        display: none;
    }

    .owl-dt-calendar-table {
        .owl-dt-calendar-cell-in-range {
            background: $primary-opacity-10;
        }
        .owl-dt-calendar-cell-selected {
            background-color: $primary;
        }
    }
}

.owl-dt-timer {
    border-top: 1px solid #0000001f;
    width: auto !important;
    margin: 0 15px;
    padding: 15px 0;
    height: unset;

    .owl-dt-timer-content {
        .owl-dt-timer-input {
            font-size: 14px;
            line-height: 20px;
            width: 36px;
            height: 40px;
            border: 1px solid $alto;
            border-radius: 6px;
        }
    }
}

.owl-dt-container-info {
    border-bottom: none;
    padding: 0 25px;
    .owl-dt-container-info-active {
        color: $primary;
        font-weight: 500;
    }
    .owl-dt-control-content {
        font-size: 16px !important;
        padding: 13px 0 !important;
        line-height: 19px;
    }
}

.owl-dt-timer-divider:before {
    top: 40%;
}
.owl-dt-timer-divider:after {
    bottom: 40%;
}

.owl-dt-container-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    height: unset;
    padding: 15px 15px 15px;
    .owl-dt-container-control-button {
        height: 44px;
        width: 100%;
        border-radius: 4px;

        &:first-child {
            background-color: $primary-second-light;
            .owl-dt-control-content {
                color: $primary-second;
            }
        }

        &:last-child {
            background: $primary;
            .owl-dt-control-content {
                color: $white;
            }
        }
    }
}
// rtl override start
[dir='rtl'] {
    .mat-select-value-text {
        margin-right: 6px !important;
    }

    .breadcrumbs {
        .breadcrumb {
            &:first-child {
                padding-right: 0 !important;
            }

            &:not(:first-child):after {
                content: '';
                display: block;
                height: 12px;
                width: 12px;
                position: absolute;
                right: 12px;
                top: 50%;
                transform: translateY(-50%);
                background: url('^assets/images/arrow-right.svg') no-repeat center center / cover;
            }

            &:not(:last-child):after {
                content: none !important;
            }
        }
    }

    .mobile {
        .country-code-form {
            .mat-form-field-label-wrapper {
                right: -22px !important;
            }
        }
    }

    app-device-preview {
        direction: rtl;

        .device-title {
            margin-right: 10px !important;
        }

        .device-wrapp {
            .device-address {
                margin-right: 10px !important;
                margin-left: 30px !important;
            }
        }
    }

    .developer-page-content {
        .client-info-row {
            .info-cell {
                &:first-child {
                    text-align: right !important;
                }
                &:last-child {
                    text-align: left !important;
                    direction: initial;
                }
            }
        }
    }

    .tutor-files-section {
        .download-link {
            .icon {
                margin-right: 0 !important;
                margin-left: 8px !important;
            }
        }
    }

    .mat-slide-toggle-bar {
        margin-right: 8px !important;
    }
}

.mat-form-field-appearance-outline {
    .mat-form-field-outline-start {
        width: auto !important;
    }
}

.mat-option {
    font-size: 14px;
    &[dir='rtl'] {
        text-align: right;
    }
}

.mat-menu-item {
    &[dir='rtl'] {
        text-align: right;
    }
}
// rtl override end

//stepper for mobile
@include mobile-only() {
    .mat-horizontal-stepper-header-container {
        overflow-x: auto;
    }
    .mat-horizontal-stepper-header {
        min-width: fit-content;
    }
}

// mat-slider override
.mat-slider-horizontal .mat-slider-ticks {
    background-image: none;
}
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
    background-color: $primary !important;
}

.slider-hmp-wrapper {
    .mat-slider-thumb-label {
        transform: rotate(45deg) !important;
        border-radius: 50% 50% 0 !important;
    }

    .mat-slider-thumb {
        transform: scale(0) !important;
    }

    .mat-slider-thumb-label-text {
        opacity: 1 !important;
    }
}
